@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: #373737;
  line-height: 150%;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
}

canvas.webgl {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

a {
  font-weight: 800;
  color: black;
  text-decoration: none;
  transition: opacity 0.2s;
}

.github-corner {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 42;
}

a:hover {
  opacity: 0.8;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/*
** LOADING SCREEN
*/

.loading-screen {
  position: fixed;
  z-index: 42;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  transition: opacity 2.4s ease-in;
}

body.started .loading-screen {
  opacity: 0;
  pointer-events: none;
}

.loading-screen__title {
  font-size: 84px;
  color: #cecece;
  position: relative;
}

body:not(.loaded) .loading-screen__button {
  opacity: 0;
}

.loading-screen__button {
  margin-top: 16px;
  padding: 8px 40px;
  border: solid 2px #666666;
  transition: all 0.4s;
  font-family: "Open Sans";
  color: #666666;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.loading-screen__button:hover {
  color: white;
  cursor: pointer;
  border-color: black;
}

.loading-screen__button::before {
  content: "";
  position: absolute;
  z-index: -1;
  background: black;
  left: -12px;
  top: -12px;
  width: 0%;
  height: calc(100% + 24px);
  transition: all 0.4s;
  transform: skew(24deg);
}

.loading-screen__button:hover::before {
  width: calc(100% + 24px);
}

.loading-screen__sound {
  margin-top: 8px;
}

.loading-screen__sound:hover {
  cursor: pointer;
}

.loading-screen__sound--disabled {
  text-decoration: line-through;
}

.loading-screen__subtitle {
  font-size: 20px;
  margin-top: 15px;
  color: #5e5465;
  position: relative;
  text-align: center;
}

.loading-screen__title--loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  color: black;
  overflow: hidden;
  white-space: nowrap;
  transition: width 0.5s;
}

/*
** POINTS
*/

.point {
  position: absolute;
  top: 50%;
  left: 50%;
}

.point__label {
  position: absolute;
  top: -50px;
  left: -21px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(5px);
  color: black;
  font-family: "Open Sans";
  font-weight: 800;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}

.point__label::before {
  content: "";
  position: absolute;
  background: #36f4fb7a;
  top: 59%;
  left: 50%;
  width: 80%;
  height: 12px;
  z-index: -1;
  transform: rotate(-5deg) translateX(-50%) translateY(-50%);
  backdrop-filter: blur(5px);
}

body:not(.camera-moving):not(.details) .point.visible .point__label {
  opacity: 1;
}

.point:hover .text {
  opacity: 1;
}

/*
** DETAILS
*/
body:not(.details) .details {
  display: none;
}

.details {
  position: fixed;
  z-index: 42;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  flex-direction: column;
  background-color: rgb(255 255 255 / 42%);
}

/*
** SCREEN
*/

.screen {
  width: 620px;
  max-width: 100%;
  padding: 32px;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.screen__title {
  font-size: 36px;
  color: black;
  position: relative;
}

.screen__title::before {
  content: "";
  position: absolute;
  background: #36f4fb7a;
  top: 100%;
  left: 0;
  width: calc(100% + 24px);
  height: 24px;
  z-index: -1;
  transform: rotate(-5deg) translateY(-100%) translateX(-12px);
}

.screen__close {
  right: 14px;
  top: 82px;
  width: 42px;
  height: 42px;
  background: rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;
  position: absolute;
  border-radius: 32px;
}

.screen__close:hover {
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.screen__close::before,
.screen__close::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 20px;
  background-color: black;
  transform: rotate(45deg) translate(-50%, -50%);
  transform-origin: top left;
  content: "";
}
.screen__close::after {
  transform: rotate(-45deg) translate(-50%, -50%);
}

.screen__content {
  border-radius: 4px;
  overflow-y: auto;
  padding: 16px 16px;
  background: rgba(255, 255, 255, 0.82);
  margin-top: 32px;
  width: 100%;
}

.screen__content img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  object-position: center;
}

.screen:not(.visible) {
  display: none;
}

/*
** EDUCATION
*/

.education {
  margin-top: 24px;
}

.education__title {
  font-size: 18px;
  font-weight: 700;
  font-family: "Open Sans";
  color: black;
}

.education__subtitle {
  margin-bottom: 8px;
  color: #9b9b9b;
}

.education__content {
  margin-top: 8px;
}

.education__content ul {
  padding: 0;
  margin-top: 16px;
  padding-left: 19px;
}

/*
** ACTIVITIES
*/

.activity {
  margin: 24px 0;
}

.activity__title {
  font-size: 18px;
  color: black;
  font-family: "Open Sans";
  font-weight: 800;
}

.activity__position {
  color: #666;
}

/*
** DATES
*/

.date {
  display: flex;
  align-items: center;
}

.date svg {
  width: 14px;
}

.date svg polygon {
  fill: #d1d1d1;
}

.date__from,
.date__to {
  padding: 2px 12px;
  background: #d1d1d1;
  color: black;
  font-size: 13px;
  font-weight: 800;
}

.date__from {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.date__to {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.date__to-icon {
  margin-left: -4px;
}

/*
** Skills
*/

.skill-category {
  margin: 24px 0;
}

.skill-category__title {
  font-size: 18px;
  color: black;
  font-family: "Open Sans";
  font-weight: 800;
}

.skill-category__content {
  color: #666;
}

/*
** Achievements
*/

.achievements {
  padding-left: 19px;
}

.achievements li {
  margin: 8px 0;
}

/*
** Contact
*/

.contact {
  display: flex;
  align-items: center;
  gap: 16px;
}

.contact svg {
  width: 32px;
}

/*
** Responsive
*/

@media (max-width: 1024px) {
  .loading-screen__title,
  .loading-screen__title--loader {
    font-size: 42px;
  }
  .loading-screen__subtitle {
    font-size: 22px;
  }

  .details,
  .screen {
    padding: 16px;
  }

  .screen__close {
    right: 0;
    top: 64px;
  }

  .skill-category,
  .activity {
    margin: 8px 0;
  }

  .education {
    margin-top: 8px;
  }
}

/* Hide the mobile message by default */
.mobile-message {
  display: none;
  text-align: center;
  padding: 20px;
  font-size: 18px;
  background-color: transparent;
  color: #000000;
  margin: 20px;
  margin-top: 40vh;
  align-items: center;
  justify-content: center;
}

.animated-lottie {
  margin-top: -2vh;
  display: none;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  display: none;
  margin-left: 35vw;
  border: transparent;
}

/* Show the mobile message on small screens */
@media (max-width: 650px) {
  .mobile-message,
  .animated-lottie {
    display: block;
  }

  /* Hide the rest of the content on small screens */
  .loading-screen,
  .details {
    display: none;
  }
}